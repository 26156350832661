export const SHARE_VIA_WHATSAPP = 'share_via_whatsapp_contest_web';
export const SHARE_VIA_TELEGRAM = 'share_via_telegram_contest_web';
export const SHARE_VIA_CONTEST = 'share_via_contest_web';
export const CONTEST_CARD_CLICK = 'contest_card_click_web';
export const CONTEST_CARD_VISIBLE = 'contest_card_visible_web';
export const CONTEST_JOINED = 'contest_joined_web';
export const CONTEST_AUTH_FAILED = 'contest_auth_failed_web';
export const CONTEST_DETAILS_JOIN_NOW = 'contest_details_join_now_web';
export const CONTEST_DETAILS_GO_TO_OFFERS = 'contest_details_go_to_offers_web';
export const CONTEST_ERROR_BOUNDARY = 'contest_error_boundary_web';
export const GLOBAL_ERROR_BOUNDARY = 'global_error_boundary_web';
export const OFFER_CARD_CLICK = 'offer_click';
export const PROFILE_OFFER_CARD_CLICK = 'profile_offer_click';
export const OFFER_CARD_VIEW = 'offer_view';
export const OFFER_LOCAL_VERIFIED = 'offer_local_verified';
export const OFFER_CTA_CLICKED = 'offer_cta_clicked';
export const USAGE_PERMISSION_REQUESTED = 'usage_permission_requested';
export const USAGE_PERMISSION_ACCEPTED = 'usage_permission_accepted';
export const USAGE_PERMISSION_REJECTED = 'usage_permission_rejected';
export const OFFER_404 = 'offer_404';
export const HELP_PAGE_OPEN = 'help_page_open';
export const SORT_UPDATED = 'sort_updated';
export const OFFER_CTA_CLICKED_V2 = 'offer_cta_clicked_v2';
export const FILTER_CLICKED = 'filter_chip_clicked';
export const SORT_CHIP_CLICKED = 'sort_chip_clicked';
export const FILTER_UPDATED = 'filter_updated';
export const USER_VOTE_INITIATED = 'user_vote_initiated';
export const USER_VOTE_SUBMITTED = 'user_vote_submitted';
export const OFFER_DETAILS_SHOW = 'offer_details_show';
